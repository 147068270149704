import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';

import { Box, Button, HStack, UnorderedList, ListItem, ChakraProvider, Flex, Center, Text, Image, VStack, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, useToast, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoPerson } from "react-icons/io5";
import { IoPersonOutline } from 'react-icons/io5';

import { registerWithPrayerRoom } from './PrayerRoom';

import useUserProfile from '../ProfilePage/Profile';

import { getAuth } from 'firebase/auth';

import { useData } from '../../App';
import { currentUserId } from '../../Utilities/firebase';

const PrayerRoomOnboarding = () => {

    const auth = getAuth();

    const user = auth.currentUser;
    const {

        PrayerRoomRegisteredUser, setPrayerRoomRegisteredUser,

        showLoginPopup, setShowLoginPopup,


        inviteSenderName,
        inviteSenderProfilePic,
        inviteSenderUsername,

        turnOnInvitePage, setTurnOnInvitePage,

    } = useData();





    const [currentPage, setCurrentPage] = useState(0);

    const isLargeScreen = useBreakpointValue({ base: false, sm: false, md: true, lg: true });

    const [message, setMessage] = useState('');


    const steps = [
        { stepNumber: '0', type: 'animation', title: 'Pray with people LIVE one-on-one', content: '', button: 'Register now' },
        { stepNumber: '1', type: 'image', title: "1. Share a prayer request that's been on your heart", content: '/userFeaturedPrayerRequest.png', button: 'Continue' },
        { stepNumber: '2', type: 'video', title: '3. Meet your prayer partner', content: '/onboardingvideo1.mp4', button: 'Join now' },
    ];
    const totalPages = 4; // Only 4 steps, excluding the video loop

    const nextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    function CircleProfileUser({ size = "24px", color = "black" }) {
        const auth = getAuth();
        const user = auth.currentUser;
        const { profilePhoto } = useUserProfile();

        return (
            <VStack spacing={2}>
                {user && profilePhoto ? (
                    <Image
                        src={profilePhoto}
                        borderRadius="full"
                        boxSize={isLargeScreen ? "50px" : "40px"}
                        boxShadow="base"
                        className="profile-image-circle"
                    />
                ) : (
                    <IoPersonOutline size={size} color={color} />
                )}
                <Text fontSize="sm">You</Text>
            </VStack>
        );
    }




    const handleFinishedPROnboarding = async () => {

        // USE THIS CODE WHEN TALKCIRCLE IS IN PRODUCTION MODE!! 
        if (user) {

            setPrayerRoomRegisteredUser(true);

            setTurnOnInvitePage(false);



            await registerWithPrayerRoom(currentUserId);

        } else {

            setShowLoginPopup(true);

            // setShowLoginPopup(true);
            // setShowFirstStepSignUp(true);
            // setSignUp(true);
            // setIsLogin(false);
            // setIsLoginPathOpen(false);
        }


        /*
          // USE THIS CODE FOR TESTING FOR DEVELOPERS ONLY!!!!!!
                if (user) {
                    onOpen();
                } else {
                    // Show login popup or handle non-authenticated user
                    setShowLoginPopup(true);
                }
        */


    };

    document.title = ("PrayerSend· PrayerRooms LIVE");







    const renderStep = (step) => {

        useEffect(() => {
            // setPrayerRoomRegisteredUser(false);
            window.scrollTo(0, 0);
        }, [step]);


        return (

            <Center h="100vh" flexDirection="column" pb={isLargeScreen ? null : (PrayerRoomRegisteredUser ? null : "1rem")}>


                {turnOnInvitePage ? (
                    <>
                        <VStack>


                            {inviteSenderName && window.location.href.includes('invitedby=') && (
                                <>
                                    <Image
                                        src={inviteSenderProfilePic}
                                        borderRadius='full'
                                        boxSize='90px'
                                        boxShadow="base"
                                        className="profile-image-circle"
                                    />
                                    <Text>{inviteSenderName}</Text>
                                    <Text mt={-3} fontSize="11px">{inviteSenderUsername}</Text>
                                    <Text m={2} fontSize={12} textAlign="center" as="b">{inviteSenderName} invited you to PrayerSend</Text>
                                </>
                            )}


                        </VStack>

                    </>

                ) : (


                    <>

                        <Text mt={isLargeScreen ? "5px" : null} textAlign="center" mb="1" fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }} color="#white" >Welcome to PrayerRooms by</Text>

                        <Image
                            src="/PrayerSendLogoText.png"
                            alt="PrayerSend Logo"
                            maxH={isLargeScreen ? "90px" : "60px"}

                        />

                    </>
                )}


                <VStack>





                    <Box textAlign="center" maxW="100%" mx="auto" mt={2}>
                        {step.stepNumber === '0' ? (

                            <>
                                <VStack mb="5">

                                    <Text fontSize={isLargeScreen ? "lg" : "md"} >{step.title}</Text>

                                    <Text as="b" fontSize={isLargeScreen ? "md" : "sm"} >Starting Friday, April 4, 2025</Text>
                                    <Text fontSize="sm">{message}</Text>
                                </VStack>
                            </>
                        ) : (
                            <>

                                <Text as="b" fontSize={isLargeScreen ? "md" : "sm"} >How it works: </Text>
                                <Text mb="5" fontSize={isLargeScreen ? "md" : "sm"}  >{step.title}</Text>

                                {step.stepNumber === '1' && (
                                    <Text fontSize={isLargeScreen ? "sm" : "sm"} >2. Listen to someone's prayer request.</Text>
                                )}

                                {step.stepNumber === '2' && (
                                    <Text as="b" fontSize={isLargeScreen ? "md" : "sm"} >Join now to complete registration and get notified when PrayerRooms open.</Text>
                                )}

                            </>

                        )}



                        {step.type === 'animation' ? (
                            <Box pl="10px" >


                                <>

                                    <HStack>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: "column",
                                                margin: 'auto',
                                            }}
                                        >
                                            <div className="video-wrapper remote-video" style={{ maxWidth: '100%', height: '120px', display: 'flex', justifyContent: 'center' }}>
                                                <video autoPlay loop muted playsInline style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }}>
                                                    <source src="/onboardingshortvideo.mp4" type="video/mp4" />
                                                </video>
                                            </div>

                                            <div className="video-wrapper local-video" style={{ maxWidth: '100%', minHeight: '120px', display: 'flex', backgroundColor: 'black', justifyContent: 'center' }}>
                                                <div className="dark-background" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <CircleProfileUser size={"24"} color={"gray"} />
                                                </div>
                                            </div>
                                        </div>

                                    </HStack>

                                </>


                            </Box>
                        ) : (
                            <>

                                <label htmlFor="profilePicInput" className="onboarding-image" style={{ display: 'block', margin: 'auto' }}>
                                    <Box >
                                        {step.type === 'image' && step.button !== 'Join now' && (
                                            <Image
                                                src={step.content}
                                                alt={step.title}
                                                maxWidth="100%"  // Image will not exceed the width of the container
                                                height="auto"    
                                                display="block"
                                                margin="0 auto"
                                            />
                                        )}
                                        {step.type === 'video' && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    //  flexDirection: isLargeScreen ? 'row' : 'column', 
                                                    flexDirection: isLargeScreen ? 'row' : 'column',
                                                    minWidth: isLargeScreen ? '100%' : null,
                                                    width: isLargeScreen ? '100%' : null,
                                                    margin: 'auto',
                                                }}
                                            >
                                                <div className="video-wrapper remote-video" style={{ maxWidth: '100%', minWidth: isLargeScreen ? '300px' : null, height: isLargeScreen ? '280px' : '120px', display: 'flex', justifyContent: 'center' }}>
                                                    <video autoPlay loop muted playsInline onEnded={nextPage} style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }}>
                                                        <source src={step.content} type="video/mp4" />
                                                    </video>
                                                </div>

                                                <div className="video-wrapper local-video" style={{ maxWidth: '100%', minWidth: isLargeScreen ? '300px' : null, minHeight: '120px', display: 'flex', backgroundColor: 'black', justifyContent: 'center' }}>
                                                    <div className="dark-background" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <CircleProfileUser size={"24"} color={"gray"} />
                                                    </div>
                                                </div>

                                            </div>


                                        )}







                                    </Box>
                                </label>




                            </>
                        )}

                    </Box>
                    {!PrayerRoomRegisteredUser & (
                        <Box mt={4} mb="5" >


                            {[...Array(totalPages)].map((_, index) => (
                                <span
                                    key={index}
                                    className={index === (currentPage === totalPages - 1 && steps[currentPage].type === 'video' ? totalPages - 1 : currentPage) ? 'active' : ''}
                                    style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', background: index <= (currentPage === totalPages - 1 && steps[currentPage].type === 'video' ? totalPages - 1 : currentPage) ? '#081b4f' : 'gray', marginRight: '5px' }}>
                                </span>
                            ))}
                        </Box>
                    )}


                    {!PrayerRoomRegisteredUser ? (


                        <Box pt={isLargeScreen ? "20px" : null}>

                            {step.stepNumber === '2' && user && (
                                <>
                                    <Text textAlign="center" fontSize="10px" pt="5px">Click join now in order to be registered!</Text>
                                </>
                            )}
                            <Button
                                mt={isLargeScreen ? "1rem" : null}
                                width="full"
                                onClick={step.button === 'Join now' ? handleFinishedPROnboarding : nextPage}
                                _color="#64a4d3"
                                colorScheme='#64a4d3'
                                size={"md"}
                                variant='outline'
                                alignItems="center"
                                cursor="pointer"
                                // _hover={{ bg: "gray.100" }} // Change background color on hover
                                borderRadius="md" // Apply border radius to mimic button style
                                p="2" //
                                _hover={{
                                    bg: '#64a4d3',
                                    color: 'white',
                                }}

                            >
                                {step.button}
                            </Button>
                        </Box>
                    ) : (
                        <>
                            <Box
                                pt="20">
                                <Button

                                    mt="1rem"
                                    width="full"
                                    size='md'
                                    variant='solid'
                                    color="white"
                                    bg='#64a4d3'
                                    border="2px"
                                    onClick={handleFinishedPROnboarding}
                                    _hover={{
                                        bg: 'gray.100',
                                        color: '#64a4d3',
                                        borderColor: '#64a4d3',
                                        transition: 'border-color 0.3s',


                                    }} >
                                    Start Now
                                </Button>
                            </Box>

                        </>
                    )}

                </VStack>

            </Center>
        )
    };

    return (




        <Center>

            {renderStep(steps[currentPage])}


        </Center>



    )
};



export default PrayerRoomOnboarding